import revive_payload_client_3M9cvznjE0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZXfQ2MNX20 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xmqt3l2TWp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_GoHEt30Qyt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1.77_knwtv72eul4wodx6q4vkmio2ku/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import sentry_client_Ub7jFNgAqV from "/opt/buildhome/repo/modules/sentry/runtime/sentry.client.ts";
import payload_client_czRGjQH5F8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XYsgTRgfNO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9HbxE0OCqv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pLSMclgrLW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_8cyM1KPsoZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.13_eslint@9.9.0_jiti@1.21._stgtvbowi36bntlr4t3mom7tiy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_bEqIPdefBg from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_g55kxzvs5xiwi5egsu7m7z4kkq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_4GkMDzVuJM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1_6lnugpwn74vcnbyct7uvp3fkfq/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_rgqUHw8gzV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1_6lnugpwn74vcnbyct7uvp3fkfq/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import umami_wsmGDYyzCw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.4/node_modules/nuxt-umami/plugins/umami.ts";
import marker_io_client_3vX6FuptAi from "/opt/buildhome/repo/plugins/marker-io.client.ts";
import defaults_8IZFrvAIr7 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_g55kxzvs5xiwi5egsu7m7z4kkq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_3M9cvznjE0,
  unhead_ZXfQ2MNX20,
  router_xmqt3l2TWp,
  _0_siteConfig_GoHEt30Qyt,
  sentry_client_Ub7jFNgAqV,
  payload_client_czRGjQH5F8,
  navigation_repaint_client_XYsgTRgfNO,
  check_outdated_build_client_9HbxE0OCqv,
  chunk_reload_client_pLSMclgrLW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8cyM1KPsoZ,
  titles_bEqIPdefBg,
  siteConfig_4GkMDzVuJM,
  inferSeoMetaPlugin_rgqUHw8gzV,
  umami_wsmGDYyzCw,
  marker_io_client_3vX6FuptAi,
  defaults_8IZFrvAIr7
]