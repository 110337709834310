<script lang="ts" setup></script>

<template>
  <div class="flex h-screen flex-col text-elbworx-black">
    <Navbar />
    <Container class="mx-auto h-full">
      <div class="mt-32 w-full pl-8 sm:pl-16 md:mt-48 lg:pl-32 2xl:pl-48 3xl:pl-72">
        <PageHeadline class="-ml-1.5">Oops</PageHeadline>

        <p class="mt-3 max-w-[24ch] text-2xl font-extrabold italic md:mt-10 md:text-5xl">
          Diese Seite hat sich in den Elbwogen verloren.
        </p>

        <AnimatedButton href="/" class="mt-10 w-fit !py-3 md:mt-14">
          Auf zu neuen Ufern!
        </AnimatedButton>
      </div>

      <div class="sticky top-[100vh]">
        <div class="h-[16rem] overflow-hidden lg:h-[32rem]">
          <Tilt
            contain
            class="h-[calc(16rem+var(--tilt-height-container))] min-w-[32rem] bg-elbworx-yellow bg-[linear-gradient(90deg,theme('colors.elbworx-yellow')_0%,theme('colors.elbworx-cyan')_100%)] lg:h-[calc(32rem+var(--tilt-height-container))]"
          />
        </div>
        <img
          class="pointer-events-none absolute -right-12 -top-8 max-h-64 w-full object-contain object-right sm:max-h-80 md:-right-20 md:-mt-24 lg:-top-16 lg:max-h-96 xl:bottom-24 xl:right-36 xl:top-auto xl:max-h-[52rem] xl:w-min"
          src="@/assets/monstera-leaf-big.png"
        />
      </div>
    </Container>
  </div>
</template>
