<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { socialLinks } from '~/components/footer/PageFooter.vue'
import { disablePageTransition } from '~/composables/transitions'

const props = defineProps<{
  open: boolean
  menuItems: { title: string; link: string }[]
}>()
const isOpen = useVModel(props, 'open')

const nuxt = useNuxtApp()

function handleNavigation() {
  disablePageTransition.value = true
  isOpen.value = false

  nuxt.hooks.hookOnce('page:finish', () => {
    console.debug('page:finish')
    disablePageTransition.value = false
  })
}
</script>

<template>
  <TransitionRoot :show="isOpen" as="template">
    <Dialog @close="() => (isOpen = false)">
      <DialogPanel class="fixed inset-0 z-[60] flex h-screen w-screen flex-col justify-center">
        <TransitionChild
          as="template"
          enter="duration-700 ease-out"
          enter-from="w-0 h-0"
          enter-to="h-[250vmax] w-[250vmax]"
          entered="h-[250vmax] w-[250vmax]"
          leave="duration-500 ease-in"
          leave-from="h-[200vmax] w-[200vmax]"
          leave-to="w-0 h-0"
        >
          <div
            aria-hidden="true"
            class="fixed right-0 top-0 -z-10 -translate-y-1/2 translate-x-[40%] rounded-full bg-elbworx-black transition-all"
          />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div>
            <div class="flex justify-center">
              <div class="flex flex-col gap-12">
                <ElbworxLink
                  v-for="item of menuItems"
                  :key="item.link"
                  :href="item.link"
                  class="font-mono font-medium text-white sm:text-lg"
                  @click="() => handleNavigation()"
                >
                  {{ item.title }}
                </ElbworxLink>
              </div>
            </div>
            <div class="mt-32 flex items-center justify-center gap-4">
              <ElbworxLink v-for="(social, idx) of socialLinks" :key="idx" :href="social.href">
                <component :is="social.icon" class="h-9 w-9 text-elbworx-yellow sm:h-8 sm:w-8" />
              </ElbworxLink>
            </div>
          </div>
        </TransitionChild>

        <button
          class="fixed right-8 top-8 px-1 pt-1 font-mono text-lg text-elbworx-yellow"
          @click="() => (isOpen = false)"
        >
          zurück
        </button>
      </DialogPanel>
    </Dialog>
  </TransitionRoot>
</template>
