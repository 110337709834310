<script lang="ts">
import { PAGE_TRANSITION_OVERLAY_CLASS } from '~/composables/transitions'
import { projectKey } from '~/types/inject'

import type { Project } from './types/project'

// preview mode
// const isPreviewMode = useLocalStorage('preview-mode', false)
// const isPreviewMode = ref(false)
// if (process.client) {
//   isPreviewMode.value =
//     isPreviewMode.value ||
//     (process.client && new URLSearchParams(window.location.search).has('preview'))
// }

// if (isPreviewMode.value) {
//   useNuxtApp().payload.serverRendered = false
//   clearNuxtData()
// }
export const PROJECT_HEADER_HEIGHT = ref('0')
</script>

<script lang="ts" setup>
const app = useNuxtApp().vueApp
app.provide(projectKey, ref<Ref<Project>>())

useSchemaOrg([
  defineLocalBusiness({
    name: 'elbworx',
    address: {
      streetAddress: 'Königsbrücker Str. 96',
      addressLocality: 'Dresden',
      addressRegion: 'Sachsen',
      postalCode: '01099',
      addressCountry: 'DE',
    },
    image: '/logo.svg',
  }),
])

const projectHeaderHeightObserverEl = ref<HTMLDivElement | null>(null)
useResizeObserver(projectHeaderHeightObserverEl, (entries) => {
  const entry = entries[0]
  const height = window
    .getComputedStyle(entry.target)
    .getPropertyValue('--project-header-height')
    .trim()
  PROJECT_HEADER_HEIGHT.value = height
})

onMounted(() => {
  const config = useRuntimeConfig().public
  // eslint-disable-next-line no-console
  console.log(`elbworx.com ${config.isStaging ? (config.commitSHA ?? 'DEV') : config.version}`)
})
</script>

<template>
  <div class="[--project-header-height:95vh] md:portrait:[--project-header-height:32rem]">
    <div
      ref="projectHeaderHeightObserverEl"
      style="height: var(--project-header-height)"
      class="invisible absolute isolate"
      aria-disabled
    />
    <NuxtLayout>
      <NuxtPage :transition="circleOverlayTopTransition" />
    </NuxtLayout>
    <div
      :class="[PAGE_TRANSITION_OVERLAY_CLASS]"
      class="fixed right-1/2 top-0 aspect-square -translate-y-1/2 translate-x-1/2 rounded-full bg-white content-['']"
    />
    <!-- <PreviewModeBanner v-if="isPreviewMode" /> -->
  </div>
</template>

<style lang="postcss">
.text-outline {
  -webkit-text-stroke: 0.015em var(--outline-color);
  -webkit-text-fill-color: transparent;
}

body:has(dialog[open]) {
  overflow: hidden;
}
</style>
